import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';

import chameleonTheme from 'core/chameleon/themes/base';
import chameleonMobileTheme from 'core/chameleon/themes/mobile';

import lightProperties from './light';
import darkProperties from './dark';

import lightMobileProperties from './lightMobile';
import darkMobileProperties from './darkMobile';


const base = atoms => stylesAtomsFactory(chameleonTheme(atoms));

export const light = base(lightProperties);
export const dark = base(darkProperties);

export const lightMobile = stylesAtomsMobileFactory(light, chameleonMobileTheme(lightMobileProperties));
export const darkMobile = stylesAtomsMobileFactory(dark, chameleonMobileTheme(darkMobileProperties));
