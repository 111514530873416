import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'news-m.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),

    theme: 'light',

    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.news-m.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/newsm',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    schemaOrg: {
      organizationName: 'news-m.ru',
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 136,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s — news-m.ru',
      defaultTitle: 'NewsM: Новости без политики',
      meta: [
        { name: 'description',
          content: 'Новости из мира Культуры, Науки, последние Технологии, советы родителям, и множество других тем на сайте NewsM.',
        },
      ],
    },

    isUnityProject: true,

    features: {
      enableRetina: true,
      enableAiRubric: true,
    },

    counterIds: {
      yandexMetrika: 94118324,
    },

    specialLinks: [
      '/about', // чтобы работала хеш навигация
      '/exports/rss.xml',
    ],

    socials: [],
  };
}
