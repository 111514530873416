import configResolver from './shared-app-config';
import Chameleon from 'core/chameleon';


export default function Site() {
  const config = configResolver();

  return (
    <Chameleon config={config} />
  );
}
