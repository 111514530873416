import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/chameleon/components/Ad';


export const Billboard = bindPropsHOC({
  name: 'Billboard',
  height: 250,
  blockId: 'R-A-344709-272',
})(Ad);

export const Ad300x600 = bindPropsHOC({
  name: '300x600',
  width: 300,
  height: 600,
  blockId: 'R-A-344709-273',
})(Ad);

export const Ad300x600Second = bindPropsHOC({
  name: '300x600_2',
  width: 300,
  height: 600,
  blockId: 'R-A-344709-274',
})(Ad);

export const SuperFooter = bindPropsHOC({
  name: 'SuperFooter',
  height: 240,
  blockId: 'R-A-344709-275',
})(Ad);

export const Center = bindPropsHOC({
  name: 'Center',
  height: 240,
  blockId: 'R-A-344709-280',
})(Ad);

export const Context = bindPropsHOC({
  name: 'Context 100x70',
  height: 70,
  blockId: 'R-A-348658-271',
})(Ad);
