import color from 'core/libs/color';
import fonts from './fonts';

import Logo from 'site/icons/Logo';

const colors = {
  primary: '#292929',
  layout: '#fff',
  content: '#fff',
  active1: '#0066ff',
  active2: '#0066ff',
  accent: '#0066ff',
  error: '#0066ff',
  input: '#fff',
  gray1: '#7B7B7B',
  gray2: '#DDDDDD',
  hover: '#0066ff',
  get divider() {
    return this.gray2;
  },
  get quote() {
    return color(this.primary).alpha(0.05).string();
  },
  get hint() {
    return color(this.primary).alpha(0.3).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.5).string();
  },
  get theme() {
    return this.active1;
  },
};

const icons = {
  logo: Logo,
};

const shapka = {
  height: 110,
  logo: {
    width: 194,
    height: 69,
  },
};

const button = {
  primary: {
    idle: {
      color: colors.layout,
    },
    hover: {
      color: colors.layout,
      background: colors.hover,
    },
    active: {
      color: colors.layout,
      background: colors.hover,
    },
  },

};

const scooter = {
  logo: {
    width: 128,
    height: 57,
  },
};

export default {
  colors,
  fonts,
  icons,
  controls: {
    shapka,
    button,
    scooter,
  },
};
