import color from 'core/libs/color';
import light from './light';

const colors = {
  primary: '#fff',
  layout: '#292929',
  content: '#292929',
  active1: '#0066ff',
  active2: '#0066ff',
  accent: '#0066ff',
  error: '#0066ff',
  input: '#000',
  gray1: '#7B7B7B',
  gray2: '#DDDDDD',
  get divider() {
    return this.gray2;
  },
  get placeholder() {
    return color(this.primary).alpha(0.5).string();
  },
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get theme() {
    return this.active1;
  },
};

export default {
  ...light,
  colors,
};
