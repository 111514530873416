const fonts = {
  display: 'Roboto, Helvetica, Arial, sans-serif',
  get text() {
    return this.display;
  },
  get nuance() {
    return this.display;
  },
};

export default fonts;
