import base from './light';

const shapka = {
  logo: {
    width: 121,
    height: 54,
  },
};

const scooter = {
  logo: {
    width: 121,
    height: 54,
  },
};

export default {
  ...base,
  controls: {
    shapka,
    scooter,
  },
};
