import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/chameleon/components/Ad';


export const TopBanner = bindPropsHOC({
  name: 'Top banner',
  height: 250,
  blockId: 'R-A-344729-269',
})(Ad);

export const Content1 = bindPropsHOC({
  name: 'Content1',
  height: 250,
  blockId: 'R-A-344729-270',
})(Ad);

export const Content2 = bindPropsHOC({
  name: 'Content2',
  height: 250,
  blockId: 'R-A-344729-271',
})(Ad);

export const Content3 = bindPropsHOC({
  name: 'Content3',
  height: 250,
  blockId: 'R-A-344729-272',
})(Ad);

export const Content4 = bindPropsHOC({
  name: 'Content4',
  height: 250,
  blockId: 'R-A-344729-273',
})(Ad);

export const Footer = bindPropsHOC({
  name: 'Footer',
  height: 250,
  blockId: 'R-A-344729-279',
})(Ad);

export const ContentSpec = bindPropsHOC({
  name: 'Spec',
  height: 250,
  blockId: 'R-A-344729-278',
})(Ad);
